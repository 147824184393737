import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import { StaticImage } from "gatsby-plugin-image";

export function Logo() {
  return (
    <StaticImage
      src="../../images/logo_ecp_png.png"
      alt="Carta Porte"
      layout="fixed"
      height={48} />
  )
}

const abrirPortal = () => {
  console.log("abrirPortal");
  window.open("http://portal.easycartaporte.com.mx:2083/", '_blank');
};

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <Logo />
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Funciones
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Estadísticas
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonios
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Button className="text-sm" onClick={abrirPortal}>Comenzar</Button>
      </div>
    </div>
  </header>
);

export default Header;
